//
// Form Control
//

// Form control
.form-control {
  box-shadow: none !important;
  padding: 0.613rem 1rem;

  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    //background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Solid style
  &.form-control-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-border;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    transition: $transition-input;
    border-radius: 0.25rem;

        display: flex;
    justify-content: center;
    align-items: center;

    // background: #f9f9f9 !important;
    // border: 1px solid #72d393 !important;
    // border-radius: 4px !important;

    .btn-otp-inactive{
      display: flex;
height: 32px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: var(--TextBox-Stroke, #E5E5E5);
color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: none;
white-space: nowrap;
    }

        .btn-otp-active{
      display: flex;
height: 32px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: var(--Primary-Color, #1F8505);
color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: none;
white-space: nowrap;
    }

    .dropdown.show > &,

    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.form-control-white {
    background-color: $body-bg;
    border-color: $body-bg;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

.form-control-disabled {
  background-color: $form-bg; 
  color: $gray-900;
  cursor: not-allowed;  
}
// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}
.icon-style {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.no-outline {
  outline: none;
  border: hidden;
  background: transparent;
  width: 85%;
}

.placholder-input {
  position: absolute;
  margin-top: 2.5rem;
  margin-left: 3rem;
}
