.sidebarMain {
  background: #f2f2f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 10px;
  height: calc(100vh - 93px);
  overflow-y: auto;

  :where(.css-dev-only-do-not-override-yp8pcc).ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title,
  :where(.css-dev-only-do-not-override-yp8pcc).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #061b01 !important;
    /* identical to box height */

    Link {
      padding-left: 0;
    }
    /* Deep Green */
  }

  :where(.css-yp8pcc).ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title,
  :where(.css-yp8pcc).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #061b01 !important;
    /* identical to box height */

    Link {
      padding-left: 0;
    }
  }

  .sidebarHeading {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    text-align: center;
    display: grid;
    gap: 11px;
    justify-content: center;
    justify-items: center;
    padding-top: 30px;

    button {
      min-width: 100px;
      width: max-content;
      padding-inline: 10px;
      height: 35px;
      background: #1f8505;
      border-radius: 4px;
      border: none;
      box-sizing: border-box;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 0.5rem;
      line-height: 21px;
      color: #f9f9f9;
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
    }
  }

  nav {
    display: grid;
    gap: 24px;
    //height: 100%;
    //overflow-y: auto;

    a {
      display: flex;
      align-items: center;
      padding: 15px 23px;
      gap: 13px;
      border-radius: 4px;
      text-decoration: none;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #08140c;

      &.sidebarMenuActive {
        background: #fbcb38;
      }

      .sidebarDropdownIcon {
        margin-left: auto;
      }
    }

    .sidebarMenuDropdown {
      display: grid;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;

      .sidebarMenuHeading {
        display: flex;
        align-items: center;
        padding: 15px 23px;
        gap: 13px;
        border-radius: 4px;
        text-decoration: none;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #08140c;

        .sidebarDropdownIcon {
          margin-left: auto;
        }
      }

      .sidebarMenuBody {
        display: none;
        padding-inline: 35px;

        a {
          display: block;
          padding: 10px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #08140c;
          &.sidebarMenuActive {
            background: #fbcb38;
          }
        }
      }

      &.sidebarMenuDropdownActive {
        background: #fef5d7;

        .sidebarMenuBody {
          display: block;
        }
      }
    }
  }

  @media (max-width: 885px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    padding-inline: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: linear width 200ms;
  }
}

.sidebarMainActivated {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px 10px;
  width: 100%;
  height: 100vh;
  transition: linear width 200ms;
  z-index: 1000;
}

.closeMobileMenu {
  display: none;

  @media (max-width: 885px) {
    position: absolute;
    right: 0;
    margin: 10px;
    display: block;
    background: none;
    border: none;
    outline: none;
    font-size: 2em;
  }
}



  .sidebarHeading-9 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    text-align: center;
    display: grid;
    gap: 11px;
    justify-content: center;
    justify-items: center;

    button {
      min-width: 100px;
      width: max-content;
      padding-inline: 10px;
      height: 35px;
      background: #1f8505;
      border-radius: 4px;
      border: none;
      box-sizing: border-box;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 0.5rem;
      line-height: 21px;
      color: #f9f9f9;
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
    }
  }