//
// Text
//

// Text colors
@each $name, $color in $theme-notification-background-colors {
  img:not(.nav-logo) {
    //width: 100%;
    // display: block;
  }


  .nav-logo{
   width: 150px;
  }

  // Base color
  .nav-links {
    display: none;
  }

  .nav-button-container {
    display: none;
    @media screen and (min-width: 992px) {
      display: flex !important;
    }
  }

  .home-icon {
    width: 29.33px;
    height: 32.08px;
  }

  .nav {
    height: 5rem;
    padding: 1rem;
    display: grid;
    align-items: end;
    transition: var(--transition);
  }

  .nav-center {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
  }

  .img-div {
    justify-items: center;
  }

  .nav-header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  .nav-btn {
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    font-size: 2rem;
    cursor: pointer;
    // justify-content: space-between
    justify-self: end;
  }

  .nav-header-signup {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }

  .nav-btn-signup {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    // justify-content: space-between
    justify-self: end;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #061b01;
  }

  .nav-btn-Proceed {
    // background: #999999;
    border-color: transparent;
    cursor: pointer;
    // justify-content: space-between
    justify-self: end;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #ffffff;

    padding: 12px 36px;
    gap: 10px;

    // background: #999999;
    border-radius: 5px;
  }

  .nav-btn-back {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    // justify-content: space-between
    justify-self: start;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #061b01;
  }

  .nav-back-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #1f8505;
  }

  // .nav-bar-margin{
  //   margin-right: 3rem;
  // }

  .navbar-fixed-signup-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--clr-white);
    z-index: 2;

    padding: 1rem;

    // padding: 12px 101px;
    // gap: 10px;

    height: 65.18px;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 992px) {
    .nav {
      background-color: "#fff";
    }

    .nav-btn {
      display: none;
    }

    .nav-bar-margin-right {
      margin-right: 6rem;
    }

    .nav-bar-margin-left {
      margin-left: 6rem;
    }

    .nav-link-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // margin-left: 10rem;
      justify-items: center;
      justify-self: center;
      margin-top: 1rem;
    }

    .nav-links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      justify-self: start;
      white-space: nowrap;
      column-gap: 3rem;
      // margin-top: 1.5rem;
    }

    .nav-links a {
      font-family: "Poppins", sans-serif;
      display: block;
      text-transform: capitalize;
      color: #061b01;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 21px;
      // letter-spacing: var(--spacing);
      transition: var(--transition);
    }

    .nav-links a::after {
      content: "";
      width: 0%;
      height: 3px;
      background-color: var(--clr-primary-5);
      margin: 0.3rem auto;
      display: grid;
      // padding: 0;
      // margin: 0;
    }

    .active::after {
      content: "";
      width: 100%;
      height: 3px;
      // background-color: var(--clr-primary-5);
      margin: 0.3rem auto;
      display: grid;
      // transition:width 0.3s linear;
    }

    .active:hover::after {
      width: 100%;
      transition: width 0.3s linear;
    }

    .nav-links a:hover::after {
      width: 100%;
      transition: width 0.3s linear;
    }

    .nav-button-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      margin-left: 4rem;
      justify-items: end;
      justify-self: end;
    }

    .nav-btn-link {
      border-color: transparent;
      color: #fff;
      cursor: pointer;
      // justify-content: space-between
      justify-self: end;
      background: #1f8505;
      border-radius: 4px;
      padding: 6px 17px;
      gap: 10px;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 21px;
      width: 126px;
      text-align: center;
    }

    .nav-btn-link-2 {
      // border-color:  #1F8505;
      border: 1px solid #1f8505;
      color: #1f8505;
      cursor: pointer;
      // justify-content: space-between
      justify-self: end;
      background: transparent;
      border-radius: 4px;
      padding: 6px 17px;
      gap: 10px;
      width: 126px;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 21px;
      /* identical to box height */

      text-align: center;
    }

    .nav-center {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 100%;
    }

    .nav-center-signup {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      width: 100%;
    }

    .navbar-fixed-signup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: var(--clr-white);
      z-index: 2;
      padding: 1rem;

      padding: 12px 101px;
      gap: 10px;

      height: 65.18px;

      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    .navbar-fixed-signup-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--clr-white);
      z-index: 2;

      padding: 1rem;

      padding: 12px 101px;
      gap: 10px;

      height: 65.18px;

      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--clr-white);
    z-index: 2;
    // box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }

  // .navbar-fixed-signup {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   background: var(--clr-white);
  //   z-index: 2;

  //   padding: 1rem;

  //   padding: 12px 101px;
  //   gap: 10px;

  //   height: 65.18px;

  //   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  // }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--clr-white);
    z-index: 4;
    display: grid;
    place-items: center;
    transition: var(--transition);
    transform: translateX((-100%));
  }

  .show-sidebar {
    transform: translateX((0));
  }

  .sidebar-links {
    text-align: center;
  }

  .sidebar-links a {
    font-size: 2rem;
    text-transform: capitalize;
    transition: var(--transition);
    color: #061b01;
    // letter-spacing: var(--spacing);
    display: inline-block;
    margin-bottom: 1.5rem;
  }

  .active {
    
  }

  .sidebar-links a::after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: #061b01;
    margin: 0.3rem auto;
    display: block;
  }

  .sidebar-links a:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .social-icons {
    display: grid;
    margin-top: 3rem;
    width: 20rem;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
  }

  .social-icon {
    font-size: 1.5rem;
    color: var(--clr-grey-1);
    transition: var(--transition);
  }

  .social-icon:hover {
    color: var(--clr-grey-5);
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
    background: transparent;
    border: transparent;
    transition: var(--transition);
    color: #000;
    cursor: pointer;
  }

  .close-btn:hover {
    color: #061b01;
  }
}
