
.mainHeader {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
  height: 93px;
  position: absolute;

  .headerLogo {
    width: 200px;
  }

  .headerLeftNav {
    display: flex;
    align-items: center;
    gap: 30px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #061B01;

    .headerUsernameRole {
      display: grid;
      justify-content: center;
      justify-items: center;

      .role {
        padding: 2px 6px;
        width: 52px;
        height: 22px;
        background: #1F8505;
        border-radius: 2px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #FFFFFF;
      }
    }

    .headerUserAvatar {
      display: flex;
      align-items: center;
      gap: 20px;
      img {
        width: 38px;
        border-radius: 50px;
      }
    }

    @media (max-width: 885px) {
      display: none;
    }
  }

  .mobileMenuTrigger {
    display: none;

    @media (max-width: 885px) {
      display: block;
      background: none;
      border: none;
      outline: none;
      font-size: 2em;
    }
  }
}

.mda-name{
color: var(--Primary-Color, #1F8505);
text-align: center;
font-family: $font-family-sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.8px;
max-width: 45%;
}